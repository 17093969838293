:root {
  --color-bg: #222;
  --color-fg: #fff;
}

@media (prefers-color-scheme: light) {
  :root {
    --color-bg: #fff;
    --color-fg: #222;
  }
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: var(--color-bg);
  color: var(--color-fg);
}

.ctn {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

#gallery {
  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 15px;

  &:hover a img {
    opacity: 0.75;
  }

  a {
    display: block;
    cursor: zoom-in;

    &:hover img {
      opacity: 1;
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }
}

.pswp__custom-caption {
  background: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  color: #fff;
  display: inline-block;
  min-width: 100px;
  padding: 5px 15px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  top: 16px;
  transform: translateX(-50%);
  text-align: center;
}
.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}
.hidden-caption-content {
  display: none;
}
@media screen and (min-width: 560px) {
  #gallery {
    grid-template-columns: repeat(2, auto);
  }
}

@media screen and (min-width: 768px) {
  #gallery {
    grid-template-columns: repeat(3, auto);
  }
}

@media screen and (min-width: 1440px) {
  #gallery {
    grid-template-columns: repeat(4, auto);
  }
}
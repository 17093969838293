:root {
  --color-bg: #222;
  --color-fg: #fff;
}

@media (prefers-color-scheme: light) {
  :root {
    --color-bg: #fff;
    --color-fg: #222;
  }
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: var(--color-bg);
  color: var(--color-fg);
  margin: 0;
  font-family: system-ui, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

.ctn {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 15px;
}

#gallery {
  grid-template-columns: repeat(1, auto);
  gap: 15px;
  display: grid;
}

#gallery:hover a img {
  opacity: .75;
}

#gallery a {
  cursor: zoom-in;
  display: block;
}

#gallery a:hover img {
  opacity: 1;
}

#gallery a img {
  width: 100%;
  max-width: 100%;
  display: block;
}

.pswp__custom-caption {
  color: #fff;
  text-align: center;
  background: #00000080;
  border-radius: 4px;
  min-width: 100px;
  padding: 5px 15px;
  font-size: 16px;
  display: inline-block;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
}

.pswp__custom-caption a {
  color: #fff;
  text-decoration: underline;
}

.hidden-caption-content {
  display: none;
}

@media screen and (width >= 560px) {
  #gallery {
    grid-template-columns: repeat(2, auto);
  }
}

@media screen and (width >= 768px) {
  #gallery {
    grid-template-columns: repeat(3, auto);
  }
}

@media screen and (width >= 1440px) {
  #gallery {
    grid-template-columns: repeat(4, auto);
  }
}

/*# sourceMappingURL=index.876fdc20.css.map */
